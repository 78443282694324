<template>
  <v-row id="rowlo-privacy-policy" class="ma-0 pa-0">
    <rowlo-title title="Robert Wloch: Datenschutzerklärung"></rowlo-title>
    <v-col
      lg="6"
      offset-lg="3"
      sm="8"
      offset-sm="2"
      cols="12"
      offset="0"
      class="pa-0"
    >
      <h1>Datenschutzerklärung</h1>
      <p>
        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck
        der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“)
        im Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres
        Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und
        Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media
        Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
        Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“
        oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
        Datenschutzgrundverordnung (DSGVO).
      </p>

      <h2>Verantwortlicher</h2>
      <p>
        Robert Wloch<br />
        Steinkuhlenweg 8<br />
        32694 Dörentrup<br />
        Deutschland<br />
        <br />
        E-Mailadresse: contact@rowlo.de<br />
        Impressum: http://www.rowlo.de/imprint
      </p>

      <h2>Arten der verarbeiteten Daten</h2>
      <p>
        - Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).<br />
        - Kontaktdaten (z.B., E-Mail, Telefonnummern).<br />
        - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).<br />
        - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
        Zugriffszeiten).<br />
        - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).
      </p>

      <h2>Kategorien betroffener Personen</h2>
      <p>
        Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die
        betroffenen Personen zusammenfassend auch als „Nutzer“).<br />
      </p>

      <h2>Zweck der Verarbeitung</h2>
      <p>
        - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
        Inhalte.<br />
        - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.<br />
        - Sicherheitsmaßnahmen.<br />
        - Reichweitenmessung/Marketing<br />
      </p>

      <h2>Verwendete Begrifflichkeiten</h2>
      <p>
        „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
        identifizierte oder identifizierbare natürliche Person (im Folgenden
        „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
        Person angesehen, die direkt oder indirekt, insbesondere mittels
        Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder
        mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck
        der physischen, physiologischen, genetischen, psychischen,
        wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen
        Person sind.
      </p>

      <p>
        „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren
        ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
        personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch
        jeden Umgang mit Daten.
      </p>

      <p>
        „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer
        Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher
        Informationen nicht mehr einer spezifischen betroffenen Person
        zugeordnet werden können, sofern diese zusätzlichen Informationen
        gesondert aufbewahrt werden und technischen und organisatorischen
        Maßnahmen nterliegen, die gewährleisten, dass die personenbezogenen
        Daten nicht einer identifizierten oder identifizierbaren natürlichen
        Person zugewiesen werden.
      </p>

      <p>
        „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener
        Daten, die darin besteht, dass diese personenbezogenen Daten verwendet
        werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
        Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich
        Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche
        Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
        Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
      </p>

      <p>
        Als „Verantwortlicher“ wird die natürliche oder juristische Person,
        Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
        anderen über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten entscheidet, bezeichnet.
      </p>

      <p>
        „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde,
        Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag
        des Verantwortlichen verarbeitet.
      </p>

      <h2>Maßgebliche Rechtsgrundlagen</h2>
      <p>
        Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
        unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich der
        Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern
        die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird,
        Folgendes: <br />
        Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs.
        1 lit. a und Art. 7 DSGVO;<br />
        Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
        Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung
        von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;<br />
        Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
        rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;<br />
        Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder
        einer anderen natürlichen Person eine Verarbeitung personenbezogener
        Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
        Rechtsgrundlage.<br />
        Die Rechtsgrundlage für die erforderliche Verarbeitung zur Wahrnehmung
        einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
        öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde
        ist Art. 6 Abs. 1 lit. e DSGVO. <br />
        Die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
        berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. <br />
        Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie
        erhoben wurden, bestimmt sich nach den Vorgaben des Art 6 Abs. 4 DSGVO.
        <br />
        Die Verarbeitung von besonderen Kategorien von Daten (entsprechend Art.
        9 Abs. 1 DSGVO) bestimmt sich nach den Vorgaben des Art. 9 Abs. 2 DSGVO.
        <br />
      </p>

      <h2>Sicherheitsmaßnahmen</h2>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter Berücksichtigung
        des Stands der Technik, der Implementierungskosten und der Art, des
        Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
        unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos
        für die Rechte und Freiheiten natürlicher Personen, geeignete technische
        und organisatorische Maßnahmen, um ein dem Risiko angemessenes
        Schutzniveau zu gewährleisten.
      </p>

      <p>
        Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
        Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
        Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der
        Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
        Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
        Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der
        Daten gewährleisten. Ferner berücksichtigen wir den Schutz
        personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von
        Hardware, Software sowie Verfahren, entsprechend dem Prinzip des
        Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche
        Voreinstellungen.
      </p>

      <h2>
        Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und
        Dritten
      </h2>
      <p>
        Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
        Personen und Unternehmen (Auftragsverarbeitern, gemeinsam
        Verantwortlichen oder Dritten) offenbaren, sie an diese übermitteln oder
        ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf
        Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der
        Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserfüllung
        erforderlich ist), Nutzer eingewilligt haben, eine rechtliche
        Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten
        Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).
      </p>

      <p>
        Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe
        offenbaren, übermitteln oder ihnen sonst den Zugriff gewähren, erfolgt
        dies insbesondere zu administrativen Zwecken als berechtigtes Interesse
        und darüberhinausgehend auf einer den gesetzlichen Vorgaben
        entsprechenden Grundlage.
      </p>

      <h2>Übermittlungen in Drittländer</h2>
      <p>
        Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
        Union (EU), des Europäischen Wirtschaftsraums (EWR) oder der Schweizer
        Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme
        von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an
        andere Personen oder Unternehmen geschieht, erfolgt dies nur, wenn es
        zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer
        Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf
        Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich
        ausdrücklicher Einwilligung oder vertraglich erforderlicher
        Übermittlung, verarbeiten oder lassen wir die Daten nur in Drittländern
        mit einem anerkannten Datenschutzniveau, zu denen die unter dem
        "Privacy-Shield" zertifizierten US-Verarbeiter gehören oder auf
        Grundlage besonderer Garantien, wie z.B. vertraglicher Verpflichtung
        durch sogenannte Standardschutzklauseln der EU-Kommission, dem Vorliegen
        von Zertifizierungen oder verbindlichen internen Datenschutzvorschriften
        verarbeiten (Art. 44 bis 49 DSGVO,
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_de"
          target="blank"
          >Informationsseite der EU-Kommission</a
        >
        ).
      </p>

      <h2>Rechte der betroffenen Personen</h2>
      <p>
        Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
        betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
        sowie auf weitere Informationen und Kopie der Daten entsprechend den
        gesetzlichen Vorgaben.
      </p>

      <p>
        Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die
        Vervollständigung der Sie betreffenden Daten oder die Berichtigung der
        Sie betreffenden unrichtigen Daten zu verlangen.
      </p>

      <p>
        Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen,
        dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ
        nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der
        Verarbeitung der Daten zu verlangen.
      </p>

      <p>
        Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die
        Sie uns bereitgestellt haben nach Maßgabe der gesetzlichen Vorgaben zu
        erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
      </p>

      <p>
        Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, eine
        Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
      </p>

      <h2>Widerrufsrecht</h2>
      <p>
        Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die Zukunft
        zu widerrufen.
      </p>

      <h2>Widerspruchsrecht</h2>
      <p>
        <strong
          >Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach
          Maßgabe der gesetzlichen Vorgaben jederzeit widersprechen. Der
          Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
          Direktwerbung erfolgen.</strong
        >
      </p>

      <h2>Cookies und Widerspruchsrecht bei Direktwerbung</h2>
      <p>
        Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der
        Nutzer gespeichert werden. Innerhalb der Cookies können unterschiedliche
        Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu
        einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während
        oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu
        speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder
        „transiente Cookies“, werden Cookies bezeichnet, die gelöscht werden,
        nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser
        schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs
        in einem Onlineshop oder ein Login-Status gespeichert werden. Als
        „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach
        dem Schließen des Browsers gespeichert bleiben. So kann z.B. der
        Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren
        Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen
        der Nutzer gespeichert werden, die für Reichweitenmessung oder
        Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden
        Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen,
        der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es
        nur dessen Cookies sind spricht man von „First-Party Cookies“).
      </p>

      <p>
        Wir können temporäre und permanente Cookies einsetzen und klären
        hierüber im Rahmen unserer Datenschutzerklärung auf.
      </p>

      <p>
        Sofern wir die Nutzer um eine Einwilligung in den Einsatz von Cookies
        bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die
        Rechtsgrundlage dieser Verarbeitung Art. 6 Abs. 1 lit. a. DSGVO.
        Ansonsten werden die personenbezogenen Cookies der Nutzer entsprechend
        den nachfolgenden Erläuterungen im Rahmen dieser Datenschutzerklärung
        auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
        Analyse, Optimierung und wirtschaftlichem Betrieb unseres
        Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) oder sofern
        der Einsatz von Cookies zur Erbringung unserer vertragsbezogenen
        Leistungen erforderlich ist, gem. Art. 6 Abs. 1 lit. b. DSGVO, bzw.
        sofern der Einsatz von Cookies für die Wahrnehmung einer Aufgabe, die im
        öffentlichen Interesse liegt erforderlich ist oder in Ausübung
        öffentlicher Gewalt erfolgt, gem. Art. 6 Abs. 1 lit. e. DSGVO,
        verarbeitet.
      </p>

      <p>
        Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
        gespeichert werden, werden sie gebeten die entsprechende Option in den
        Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies
        können in den Systemeinstellungen des Browsers gelöscht werden. Der
        Ausschluss von Cookies kann zu Funktionseinschränkungen dieses
        Onlineangebotes führen.
      </p>

      <p>
        Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
        Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der
        Dienste, vor allem im Fall des Trackings, über die US-amerikanische
        Seite
        <a href="http://www.aboutads.info/choices/"
          >http://www.aboutads.info/choices/</a
        >
        oder die EU-Seite
        <a href="http://www.youronlinechoices.com/"
          >http://www.youronlinechoices.com/</a
        >
        erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels
        deren Abschaltung in den Einstellungen des Browsers erreicht werden.
        Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
        dieses Onlineangebotes genutzt werden können.
      </p>

      <h2>Löschung von Daten</h2>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
        Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht
        im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die
        bei uns gespeicherten Daten gelöscht, sobald sie für ihre
        Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
        gesetzlichen Aufbewahrungspflichten entgegenstehen.
      </p>

      <p>
        Sofern die Daten nicht gelöscht werden, weil sie für andere und
        gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung
        eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere
        Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
        steuerrechtlichen Gründen aufbewahrt werden müssen.
      </p>

      <h2>Änderungen und Aktualisierungen der Datenschutzerklärung</h2>
      <p>
        Wir bitten Sie sich regelmäßig über den Inhalt unserer
        Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung
        an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen
        dies erforderlich machen. Wir informieren Sie, sobald durch die
        Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder
        eine sonstige individuelle Benachrichtigung erforderlich wird.
      </p>

      <h2>Kommentare und Beiträge</h2>
      <p>
        Wenn Nutzer Kommentare oder sonstige Beiträge hinterlassen, können ihre
        IP-Adressen auf Grundlage unserer berechtigten Interessen im Sinne des
        Art. 6 Abs. 1 lit. f. DSGVO für 7 Tage gespeichert werden. Das erfolgt
        zu unserer Sicherheit, falls jemand in Kommentaren und Beiträgen
        widerrechtliche Inhalte hinterlässt (Beleidigungen, verbotene politische
        Propaganda, etc.). In diesem Fall können wir selbst für den Kommentar
        oder Beitrag belangt werden und sind daher an der Identität des
        Verfassers interessiert.
      </p>

      <p>
        Des Weiteren behalten wir uns vor, auf Grundlage unserer berechtigten
        Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO, die Angaben der Nutzer
        zwecks Spamerkennung zu verarbeiten.
      </p>

      <p>
        Auf derselben Rechtsgrundlage behalten wir uns vor, im Fall von Umfragen
        die IP-Adressen der Nutzer für deren Dauer zu speichern und Cookies zu
        verwenden, um Mehrfachabstimmungen zu vermeiden.
      </p>

      <p>
        Die im Rahmen der Kommentare und Beiträge mitgeteilte Informationen zur
        Person, etwaige Kontakt- sowie Websiteinformationen als auch die
        inhaltlichen Angaben, werden von uns bis zum Widerspruch der Nutzer
        dauerhaft gespeichert.
      </p>

      <h2>Kommentarabonnements</h2>
      <p>
        Die Nachfolgekommentare können durch Nutzer mit deren Einwilligung gem.
        Art. 6 Abs. 1 lit. a DSGVO abonniert werden. Die Nutzer erhalten eine
        Bestätigungsemail, um zu überprüfen, ob sie der Inhaber der eingegebenen
        Emailadresse sind. Nutzer können laufende Kommentarabonnements jederzeit
        abbestellen. Die Bestätigungsemail wird Hinweise zu den
        Widerrufsmöglichkeiten enthalten. Für die Zwecke des Nachweises der
        Einwilligung der Nutzer, speichern wir den Anmeldezeitpunkt nebst der
        IP-Adresse der Nutzer und löschen diese Informationen, wenn Nutzer sich
        von dem Abonnement abmelden.
      </p>

      <p>
        Sie können den Empfang unseres Abonnements jederzeit kündigen, d.h. Ihre
        Einwilligungen widerrufen. Wir können die ausgetragenen E-Mailadressen
        bis zu drei Jahren auf Grundlage unserer berechtigten Interessen
        speichern bevor wir sie löschen, um eine ehemals gegebene Einwilligung
        nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck
        einer möglichen Abwehr von Ansprüchen beschränkt. Ein individueller
        Löschungsantrag ist jederzeit möglich, sofern zugleich das ehemalige
        Bestehen einer Einwilligung bestätigt wird.
      </p>

      <h2>Akismet Anti-Spam-Prüfung</h2>
      <p>
        Unser Onlineangebot nutzt den Dienst „Akismet“, der von der Automattic
        Inc., 60 29th Street #343, San Francisco, CA 94110, USA, angeboten wird.
        Die Nutzung erfolgt auf Grundlage unserer berechtigten Interessen im
        Sinne des Art. 6 Abs. 1 lit. f) DSGVO. Mit Hilfe dieses Dienstes werden
        Kommentare echter Menschen von Spam-Kommentaren unterschieden. Dazu
        werden alle Kommentarangaben an einen Server in den USA verschickt, wo
        sie analysiert und für Vergleichszwecke vier Tage lang gespeichert
        werden. Ist ein Kommentar als Spam eingestuft worden, werden die Daten
        über diese Zeit hinaus gespeichert. Zu diesen Angaben gehören der
        eingegebene Name, die Emailadresse, die IP-Adresse, der Kommentarinhalt,
        der Referrer, Angaben zum verwendeten Browser sowie dem Computersystem
        und die Zeit des Eintrags.
      </p>

      <p>
        Nähere Informationen zur Erhebung und Nutzung der Daten durch Akismet
        finden sich in den Datenschutzhinweisen von Automattic:
        <a target="_blank" href="https://automattic.com/privacy/"
          >https://automattic.com/privacy/</a
        >.
      </p>

      <p>
        Nutzer können gerne Pseudonyme nutzen, oder auf die Eingabe des Namens
        oder der Emailadresse verzichten. Sie können die Übertragung der Daten
        komplett verhindern, indem Sie unser Kommentarsystem nicht nutzen. Das
        wäre schade, aber leider sehen wir sonst keine Alternativen, die ebenso
        effektiv arbeiten.
      </p>

      <h2>Abruf von Emojis und Smilies</h2>
      <p>
        Innerhalb unseres WordPress-Blogs werden grafische Emojis (bzw.
        Smilies), d.h. kleine grafische Dateien, die Gefühle ausdrücken,
        eingesetzt, die von externen Servern bezogen werden. Hierbei erheben die
        Anbieter der Server, die IP-Adressen der Nutzer. Dies ist notwendig,
        damit die Emojie-Dateien an die Browser der Nutzer übermittelt werden
        können. Der Emojie-Service wird von der Automattic Inc., 60 29th Street
        #343, San Francisco, CA 94110, USA, angeboten. Datenschutzhinweise von
        Automattic:
        <a target="_blank" href="https://automattic.com/privacy/"
          >https://automattic.com/privacy/</a
        >. Die verwendeten Server-Domains sind s.w.org und twemoji.maxcdn.com,
        wobei es sich unseres Wissens nach um sog. Content-Delivery-Networks
        handelt, also Server, die lediglich einer schnellen und sicheren
        Übermittlung der Dateien dienen und die personenbezogenen Daten der
        Nutzer nach der Übermittlung gelöscht werden.
      </p>

      <p>
        Die Nutzung der Emojis erfolgt auf Grundlage unserer berechtigten
        Interessen, d.h. Interesse an einer attraktiven Gestaltung unseres
        Onlineangebotes gem. Art. 6 Abs. 1 lit. f. DSGVO.
      </p>

      <h2>Soundcloud</h2>
      <p>
        Unsere Podcasts werden auf der Plattform „Soundcloud“, angeboten von
        SoundCloud Limited, Rheinsberger Str. 76/77, 10115 Berlin, Deutschland
        gespeichert und werden von dieser aus Platform wiedergegeben.
      </p>

      <p>
        Zu diesem Zweck binden wir sog. Soundcloud-Widgets in unsere Website
        ein. Dabei handelt es sich um Abspielsoftware, mit der Nutzer die
        Podcasts abspielen können. Hierbei kann Soundcloud messen, welche
        Podcasts in welchem Umfang gehört werden und diese Information pseudonym
        für statistische und betriebswirtschaftliche Zwecke verarbeiten. Hierzu
        können Cookies in den Browsern der Nuzer gespeichert und zwecks Bildung
        von Nutzerprofilen, z.B. für Zwecke der Ausgabee von Anzeigen, die den
        potentiellen Interessen der Nutzer entsprechen, verarbeitet werden. Im
        Fall von Nutzern, die bei Soundcloud registriert sind, kann Soundcloud
        die Hörinformationen deren Profilen zuordnen.
      </p>

      <p>
        Die Nutzung erfolgt auf Grundlage unserer berechtigten Interessen, d.h.
        Interesse an einer sicheren und effizienten Bereitstellung, Analyse
        sowie Optimierung unseres Audioangebotes gem. Art. 6 Abs. 1 lit. f.
        DSGVO.
      </p>

      <p>
        Weitere Informationen und Widerspruchsmöglichkeiten finden sich in der
        Datenschutzerklärung von Soundcloud:
        <a target="_blank" href="https://soundcloud.com/pages/privacy"
          >https://soundcloud.com/pages/privacy</a
        >.
      </p>

      <h2>Hosting und E-Mail-Versand</h2>
      <p>
        Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
        Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
        Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
        Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische
        Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
        Onlineangebotes einsetzen.
      </p>

      <p>
        Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
        Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
        Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
        Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer
        effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes
        gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
        Auftragsverarbeitungsvertrag).
      </p>

      <h2>Erhebung von Zugriffsdaten und Logfiles</h2>
      <p>
        Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
        berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
        über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
        (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
        abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
        Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
        das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
        IP-Adresse und der anfragende Provider.
      </p>

      <p>
        Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung
        von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7
        Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung
        zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
        jeweiligen Vorfalls von der Löschung ausgenommen.
      </p>

      <h2>Content-Delivery-Network vonCloudflare</h2>
      <p>
        Wir setzen ein so genanntes "Content Delivery Network" (CDN), angeboten
        von Cloudflare, Inc., 101 Townsend St, San Francisco, CA 94107, USA,
        ein. Cloudflare ist unter dem Privacy-Shield-Abkommen zertifiziert und
        bietet hierdurch eine Garantie, das europäische Datenschutzrecht
        einzuhalten (
        <a
          target="_blank"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&amp;status=Active"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&amp;status=Active</a
        >).
      </p>

      <p>
        Ein CDN ist ein Dienst, mit dessen Hilfe Inhalte unseres
        Onlineangebotes, insbesondere große Mediendateien, wie Grafiken oder
        Skripte mit Hilfe regional verteilter und über das Internet verbundener
        Server, schneller ausgeliefert werden. Die Verarbeitung der Daten der
        Nutzer erfolgt alleine zu den vorgenannten Zwecken und der
        Aufrechterhaltung der Sicherheit und Funktionsfähigkeit des CDN.
      </p>

      <p>
        Die Nutzung erfolgt auf Grundlage unserer berechtigten Interessen, d.h.
        Interesse an einer sicheren und effizienten Bereitstellung, Analyse
        sowie Optimierung unseres Onlineangebotes gem. Art. 6 Abs. 1 lit. f.
        DSGVO.
      </p>

      <p>
        Weitere Informationen erhalten Sie in der Datenschutzerklärung von
        Cloudflare:&nbsp;<a
          target="_blank"
          href="https://www.cloudflare.com/security-policy"
          >https://www.cloudflare.com/security-policy</a
        >.
      </p>

      <h2>Content-Delivery-Network von StackPath</h2>
      <p>
        Wir setzen ein so genanntes "Content Delivery Network" (CDN), angeboten
        von StackPath, LLC, 2021 McKinney Avenue, Suite 1100 Dallas, Texas
        75201, USA, ein. StackPath ist unter dem Privacy-Shield-Abkommen
        zertifiziert und bietet hierdurch eine Garantie, das europäische
        Datenschutzrecht einzuhalten (<a
          target="_blank"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&amp;status=Active"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&amp;status=Active</a
        >).
      </p>

      <p>
        Ein CDN ist ein Dienst, mit dessen Hilfe Inhalte unseres
        Onlineangebotes, insbesondere große Mediendateien, wie Grafiken oder
        Skripte mit Hilfe regional verteilter und über das Internet verbundener
        Server, schneller ausgeliefert werden. Die Verarbeitung der Daten der
        Nutzer erfolgt alleine zu den vorgenannten Zwecken und der
        Aufrechterhaltung der Sicherheit und Funktionsfähigkeit des CDN.
      </p>

      <p>
        Die Nutzung erfolgt auf Grundlage unserer berechtigten Interessen, d.h.
        Interesse an einer sicheren und effizienten Bereitstellung, Analyse
        sowie Optimierung unseres Onlineangebotes.
      </p>

      <p>
        Weitere Informationen erhalten Sie in der Datenschutzerklärung von
        StackPath:
        <a
          target="_blank"
          href="https://www.stackpath.com/legal/privacy-statement"
          >https://www.stackpath.com/legal/privacy-statement</a
        >.
      </p>

      <h2>Onlinepräsenzen in sozialen Medien</h2>
      <p>
        Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
        Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern
        kommunizieren und sie dort über unsere Leistungen informieren zu können.
      </p>

      <p>
        Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes
        der Europäischen Union verarbeitet werden können. Hierdurch können sich
        für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte
        der Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter die
        unter dem Privacy-Shield zertifiziert sind, weisen wir darauf hin, dass
        sie sich damit verpflichten, die Datenschutzstandards der EU
        einzuhalten.
      </p>

      <p>
        Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs- und
        Werbezwecke verarbeitet. So können z.B. aus dem Nutzungsverhalten und
        sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt
        werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B.
        Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die
        mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken
        werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in
        denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert
        werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der
        von den Nutzern verwendeten Geräte gespeichert werden (insbesondere wenn
        die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen
        eingeloggt sind).
      </p>

      <p>
        Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf
        Grundlage unserer berechtigten Interessen an einer effektiven
        Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6
        Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern der
        Plattformen um eine Einwilligung in die vorbeschriebene
        Datenverarbeitung gebeten werden, ist die Rechtsgrundlage der
        Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
      </p>

      <p>
        Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der
        Widerspruchsmöglichkeiten (Opt-Out), verweisen wir auf die nachfolgend
        verlinkten Angaben der Anbieter.
      </p>

      <p>
        Auch im Fall von Auskunftsanfragen und der Geltendmachung von
        Nutzerrechten, weisen wir darauf hin, dass diese am effektivsten bei den
        Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
        Zugriff auf die Daten der Nutzer und können direkt entsprechende
        Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe
        benötigen, dann können Sie sich an uns wenden.
      </p>

      <p>
        - Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd., 4 Grand Canal
        Square, Grand Canal Harbour, Dublin 2, Irland) auf Grundlage einer
        <a
          target="_blank"
          href="https://www.facebook.com/legal/terms/page_controller_addendum"
          >Vereinbarung über gemeinsame Verarbeitung personenbezogener Daten</a
        >
        - Datenschutzerklärung:
        <a target="_blank" href="https://www.facebook.com/about/privacy/"
          >https://www.facebook.com/about/privacy/</a
        >, speziell für Seiten:
        <a
          target="_blank"
          href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
        >
          https://www.facebook.com/
          legal/terms/information_about_page_insights_data</a
        >
        , Opt-Out:
        <a target="_blank" href="https://www.facebook.com/settings?tab=ads"
          >https://www.facebook.com/settings?tab=ads</a
        >
        und
        <a target="_blank" href="http://www.youronlinechoices.com"
          >http://www.youronlinechoices.com</a
        >, Privacy Shield:
        <a
          target="_blank"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a
        >.
      </p>

      <p>
        - Google/ YouTube (Google Ireland Limited, Gordon House, Barrow Street,
        Dublin 4, Irland) – Datenschutzerklärung: &nbsp;<a
          target="_blank"
          href="https://policies.google.com/privacy"
          >https://policies.google.com/privacy</a
        >, Opt-Out:
        <a target="_blank" href="https://adssettings.google.com/authenticated"
          >https://adssettings.google.com/authenticated</a
        >, Privacy Shield:
        <a
          target="_blank"
          href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
        >
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a
        >.
      </p>

      <p>
        - Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025,
        USA) – Datenschutzerklärung/ Opt-Out:
        <a target="_blank" href="http://instagram.com/about/legal/privacy/"
          >http://instagram.com/about/legal/privacy/</a
        >.
      </p>

      <p>
        - Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco,
        CA 94103, USA) - Datenschutzerklärung:
        <a target="_blank" href="https://twitter.com/de/privacy"
          >https://twitter.com/de/privacy</a
        >, Opt-Out:
        <a target="_blank" href="https://twitter.com/personalization"
          >https://twitter.com/personalization</a
        >, Privacy Shield:
        <a
          target="_blank"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</a
        >.
      </p>

      <p>
        - Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA)
        – Datenschutzerklärung/ Opt-Out:
        <a target="_blank" href="https://about.pinterest.com/de/privacy-policy"
          >https://about.pinterest.com/de/privacy-policy</a
        >.
      </p>

      <p>
        - LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2,
        Irland) - Datenschutzerklärung
        <a target="_blank" href="https://www.linkedin.com/legal/privacy-policy"
          >https://www.linkedin.com/legal/privacy-policy</a
        >
        , Opt-Out:
        <a
          target="_blank"
          href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
        >
          https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a
        >, Privacy Shield:&nbsp;<a
          target="_blank"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active</a
        >.
      </p>

      <p>
        - Xing (XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland) -
        Datenschutzerklärung/ Opt-Out:
        <a
          target="_blank"
          href="https://privacy.xing.com/de/datenschutzerklaerung"
          >https://privacy.xing.com/de/datenschutzerklaerung</a
        >.
      </p>

      <p>
        - Wakalet (Wakelet Limited, 76 Quay Street, Manchester, M3 4PR, United
        Kingdom) - Datenschutzerklärung/ Opt-Out:
        <a target="_blank" href="https://wakelet.com/privacy.html"
          >https://wakelet.com/privacy.html</a
        >.
      </p>

      <p>
        - Soundcloud (SoundCloud Limited, Rheinsberger Str. 76/77, 10115 Berlin,
        Deutschland) - Datenschutzerklärung/ Opt-Out:
        <a target="_blank" href="https://soundcloud.com/pages/privacy"
          >https://soundcloud.com/pages/privacy</a
        >.
      </p>

      <h2>Einbindung von Diensten und Inhalten Dritter</h2>
      <p>
        Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
        berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
        wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6
        Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern
        ein, um deren Inhalte und Services, wie z.B. Videos oder Schriftarten
        einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”).
      </p>

      <p>
        Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die
        IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte
        nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
        Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche
        Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich
        zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so
        genannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
        bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
        "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
        Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen
        können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und
        unter anderem technische Informationen zum Browser und Betriebssystem,
        verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung
        unseres Onlineangebotes enthalten, als auch mit solchen Informationen
        aus anderen Quellen verbunden werden.
      </p>

      <h2>Youtube</h2>
      <p>
        Wir binden die Videos der Plattform “YouTube” des Anbieters Google
        Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein.
        Datenschutzerklärung:
        <a target="_blank" href="https://www.google.com/policies/privacy/"
          >https://www.google.com/policies/privacy/</a
        >, Opt-Out:
        <a target="_blank" href="https://adssettings.google.com/authenticated"
          >https://adssettings.google.com/authenticated</a
        >.
      </p>

      <h2>Google Fonts</h2>
      <p>
        Wir binden die Schriftarten ("Google Fonts") des Anbieters Google
        Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein.
        Nach Angaben von Google werden die Daten der Nutzer allein zu Zwecken
        der Darstellung der Schriftarten im Browser der Nutzer verwendet. Die
        Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an
        einer technisch sicheren, wartungsfreien und effizienten Nutzung von
        Schriftarten, deren einheitlicher Darstellung sowie Berücksichtigung
        möglicher lizenzrechtlicher Restriktionen für deren Einbindung.
        Datenschutzerklärung:
        <a target="_blank" href="https://www.google.com/policies/privacy/"
          >https://www.google.com/policies/privacy/</a
        >.
      </p>

      <h2>Google ReCaptcha</h2>
      <p>
        Wir binden die Funktion zur Erkennung von Bots, z.B. bei Eingaben in
        Onlineformularen ("ReCaptcha") des Anbieters GGoogle Ireland Limited,
        Gordon House, Barrow Street, Dublin 4, Irland, ein.
        Datenschutzerklärung:
        <a target="_blank" href="https://www.google.com/policies/privacy/"
          >https://www.google.com/policies/privacy/</a
        >, Opt-Out:
        <a target="_blank" href="https://adssettings.google.com/authenticated"
          >https://adssettings.google.com/authenticated</a
        >.
      </p>

      <h2>Twitter</h2>
      <p>
        Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des
        Dienstes Twitter, angeboten durch die Twitter Inc., 1355 Market Street,
        Suite 900, San Francisco, CA 94103, USA, eingebunden werden. Hierzu
        können z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen
        gehören, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von
        Twitter teilen können.
      </p>

      <p>
        Sofern die Nutzer Mitglieder der Plattform Twitter sind, kann Twitter
        den Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der
        Nutzer zuordnen. Twitter ist unter dem Privacy-Shield-Abkommen
        zertifiziert und bietet hierdurch eine Garantie, das europäische
        Datenschutzrecht einzuhalten (
        <a
          target="_blank"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</a
        >). Datenschutzerklärung:
        <a target="_blank" href="https://twitter.com/de/privacy"
          >https://twitter.com/de/privacy</a
        >, Opt-Out:
        <a target="_blank" href="https://twitter.com/personalization"
          >https://twitter.com/personalization</a
        >.
      </p>

      <h2>Pinterest</h2>
      <p>
        Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des
        Dienstes Pinterest, angeboten durch die Pinterest Inc., 635 High Street,
        Palo Alto, CA, 94301, USA, eingebunden werden. Hierzu können z.B.
        Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit
        denen Nutzer Inhalte dieses Onlineangebotes innerhalb von Pinterest
        teilen können. Sofern die Nutzer Mitglieder der Plattform Pinterest
        sind, kann Pinterest den Aufruf der o.g. Inhalte und Funktionen den
        dortigen Profilen der Nutzer zuordnen. Datenschutzerklärung von
        Pinterest:
        <a target="_blank" href="https://about.pinterest.com/de/privacy-policy"
          >https://about.pinterest.com/de/privacy-policy</a
        >.
      </p>

      <h2>Xing</h2>
      <p>
        Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des
        Dienstes Xing, angeboten durch die XING AG, Dammtorstraße 29-32, 20354
        Hamburg, Deutschland, eingebunden werden. Hierzu können z.B. Inhalte wie
        Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer
        Inhalte dieses Onlineangebotes innerhalb von Xing teilen können. Sofern
        die Nutzer Mitglieder der Plattform Xing sind, kann Xing den Aufruf der
        o.g. Inhalte und Funktionen den dortigen Profilen der Nutzer zuordnen.
        Datenschutzerklärung von Xing:
        <a
          target="_blank"
          href="https://privacy.xing.com/de/datenschutzerklaerung"
          >https://privacy.xing.com/de/datenschutzerklaerung</a
        >.
      </p>

      <h2>LinkedIn</h2>
      <p>
        Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des
        Dienstes LinkedIn, angeboten durch die LinkedIn Ireland Unlimited
        Company Wilton Place, Dublin 2, Irland, eingebunden werden. Hierzu
        können z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen
        gehören, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von
        LinkedIn teilen können. Sofern die Nutzer Mitglieder der Plattform
        LinkedIn sind, kann LinkedIn den Aufruf der o.g. Inhalte und Funktionen
        den dortigen Profilen der Nutzer zuordnen. Datenschutzerklärung von
        LinkedIn:
        <a target="_blank" href="https://www.linkedin.com/legal/privacy-policy"
          >https://www.linkedin.com/legal/privacy-policy.</a
        >. LinkedIn ist unter dem Privacy-Shield-Abkommen zertifiziert und
        bietet hierdurch eine Garantie, das europäische Datenschutzrecht
        einzuhalten (<a
          target="_blank"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active</a
        >). Datenschutzerklärung:
        <a target="_blank" href="https://www.linkedin.com/legal/privacy-policy"
          >https://www.linkedin.com/legal/privacy-policy</a
        >, Opt-Out:
        <a
          target="_blank"
          href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
        >
          https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a
        >.
      </p>

      <a href="https://datenschutz-generator.de" rel="nofollow" target="_blank"
        >Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a
      >
    </v-col>
  </v-row>
</template>

<script>
import { eventBus } from '@/main.js'

export default {
  name: 'rowlo-privacy-policy',
  created() {
    eventBus.updateI18nFromRoute(this.$route.query)
  },
}
</script>

<style scoped>
h1 {
  font-size: 28px;
}
h2 {
  font-size: 21px;
}
h3 {
  font-size: 1.17em;
}
p {
  font-size: 14px;
}
.permalink_section {
  width: 300px;
  height: 400px;
  border: 1px solid red;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
